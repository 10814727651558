.icon {
  display: inline-flex;
  align-items: baseline;
}

.icon svg {
  inline-size: 0.75em;
  inline-size: 1cap;
  block-size: 0.75em;
  block-size: 1cap;
  margin-inline-end: var(--icon-space, 0);
}
