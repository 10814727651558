.p--2 {
  padding: var(--size--2);
}

.p--1 {
  padding: var(--size--1);
}

.p-0 {
  padding: var(--size-0);
}

.p-1 {
  padding: var(--size-1);
}

.p-2 {
  padding: var(--size-2);
}

.p-3 {
  padding: var(--size-3);
}

.p-4 {
  padding: var(--size-4);
}

.p-5 {
  padding: var(--size-5);
}

.p-6 {
  padding: var(--size-6);
}

.p-7 {
  padding: var(--size-7);
}

.p-8 {
  padding: var(--size-8);
}

.p-9 {
  padding: var(--size-9);
}

/**
 * Horizontal
 */

.px--2 {
  padding-inline: var(--size--2);
}

.px--1 {
  padding-inline: var(--size--1);
}

.px-0 {
  padding-inline: var(--size-0);
}

.px-1 {
  padding-inline: var(--size-1);
}

.px-2 {
  padding-inline: var(--size-2);
}

.px-3 {
  padding-inline: var(--size-3);
}

.px-4 {
  padding-inline: var(--size-4);
}

.px-5 {
  padding-inline: var(--size-5);
}

.px-6 {
  padding-inline: var(--size-6);
}

.px-7 {
  padding-inline: var(--size-7);
}

.px-8 {
  padding-inline: var(--size-8);
}

.px-9 {
  padding-inline: var(--size-9);
}

/**
 * Left
 */

.pl--2 {
  padding-inline-start: var(--size--2);
}

.pl--1 {
  padding-inline-start: var(--size--1);
}

.pl-0 {
  padding-inline-start: var(--size-0);
}

.pl-1 {
  padding-inline-start: var(--size-1);
}

.pl-2 {
  padding-inline-start: var(--size-2);
}

.pl-3 {
  padding-inline-start: var(--size-3);
}

.pl-4 {
  padding-inline-start: var(--size-4);
}

.pl-5 {
  padding-inline-start: var(--size-5);
}

.pl-6 {
  padding-inline-start: var(--size-6);
}

.pl-7 {
  padding-inline-start: var(--size-7);
}

.pl-8 {
  padding-inline-start: var(--size-8);
}

.pl-9 {
  padding-inline-start: var(--size-9);
}

/**
 * Right
 */

.pr--2 {
  padding-inline-end: var(--size--2);
}

.pr--1 {
  padding-inline-end: var(--size--1);
}

.pr-0 {
  padding-inline-end: var(--size-0);
}

.pr-1 {
  padding-inline-end: var(--size-1);
}

.pr-2 {
  padding-inline-end: var(--size-2);
}

.pr-3 {
  padding-inline-end: var(--size-3);
}

.pr-4 {
  padding-inline-end: var(--size-4);
}

.pr-5 {
  padding-inline-end: var(--size-5);
}

.pr-6 {
  padding-inline-end: var(--size-6);
}

.pr-7 {
  padding-inline-end: var(--size-7);
}

.pr-8 {
  padding-inline-end: var(--size-8);
}

.pr-9 {
  padding-inline-end: var(--size-9);
}

/**
 * Vertical
 */

.py--2 {
  padding-block: var(--size--2);
}

.py--1 {
  padding-block: var(--size--1);
}

.py-0 {
  padding-block: var(--size-0);
}

.py-1 {
  padding-block: var(--size-1);
}

.py-2 {
  padding-block: var(--size-2);
}

.py-3 {
  padding-block: var(--size-3);
}

.py-4 {
  padding-block: var(--size-4);
}

.py-5 {
  padding-block: var(--size-5);
}

.py-6 {
  padding-block: var(--size-6);
}

.py-7 {
  padding-block: var(--size-7);
}

.py-8 {
  padding-block: var(--size-8);
}

.py-9 {
  padding-block: var(--size-9);
}

/**
 * Top
 */

.pt--2 {
  padding-block-start: var(--size--2);
}

.pt--1 {
  padding-block-start: var(--size--1);
}

.pt-0 {
  padding-block-start: var(--size-0);
}

.pt-1 {
  padding-block-start: var(--size-1);
}

.pt-2 {
  padding-block-start: var(--size-2);
}

.pt-3 {
  padding-block-start: var(--size-3);
}

.pt-4 {
  padding-block-start: var(--size-4);
}

.pt-5 {
  padding-block-start: var(--size-5);
}

.pt-6 {
  padding-block-start: var(--size-6);
}

.pt-7 {
  padding-block-start: var(--size-7);
}

.pt-8 {
  padding-block-start: var(--size-8);
}

.pt-9 {
  padding-block-start: var(--size-9);
}

/**
 * Bottom
 */

.pb--2 {
  padding-block-end: var(--size--2);
}

.pb--1 {
  padding-block-end: var(--size--1);
}

.pb-0 {
  padding-block-end: var(--size-0);
}

.pb-1 {
  padding-block-end: var(--size-1);
}

.pb-2 {
  padding-block-end: var(--size-2);
}

.pb-3 {
  padding-block-end: var(--size-3);
}

.pb-4 {
  padding-block-end: var(--size-4);
}

.pb-5 {
  padding-block-end: var(--size-5);
}

.pb-6 {
  padding-block-end: var(--size-6);
}

.pb-7 {
  padding-block-end: var(--size-7);
}

.pb-8 {
  padding-block-end: var(--size-8);
}

.pb-9 {
  padding-block-end: var(--size-9);
}
