:root {
  /**
   * Typography
   */

  /* stylelint-disable value-keyword-case */
  --font-sans: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  --font-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
  /* stylelint-enable */

  --font-text: var(--font-sans);
  --font-display: var(--font-text);
  --measure: 60ch;

  /**
   * Modular scale
   */

  --ratio: 1.2;
  --size-0: 1rem;
  --size--1: calc(var(--size-0) * pow(var(--ratio), -1));
  --size--2: calc(var(--size-0) * pow(var(--ratio), -2));
  --size-1: calc(var(--size-0) * pow(var(--ratio), 1));
  --size-2: calc(var(--size-0) * pow(var(--ratio), 2));
  --size-3: calc(var(--size-0) * pow(var(--ratio), 3));
  --size-4: calc(var(--size-0) * pow(var(--ratio), 4));
  --size-5: calc(var(--size-0) * pow(var(--ratio), 5));
  --size-6: calc(var(--size-0) * pow(var(--ratio), 6));
  --size-7: calc(var(--size-0) * pow(var(--ratio), 7));
  --size-8: calc(var(--size-0) * pow(var(--ratio), 8));
  --size-9: calc(var(--size-0) * pow(var(--ratio), 9));

  /**
   * Colors
   */

  --color-primary: LinkText;
}
