.fz--2 {
  font-size: var(--size--2);
}

.fz--1 {
  font-size: var(--size--1);
}

.fz-0 {
  font-size: var(--size-0);
}

.fz-1 {
  font-size: var(--size-1);
}

.fz-2 {
  font-size: var(--size-2);
}

.fz-3 {
  font-size: var(--size-3);
}

.fz-4 {
  font-size: var(--size-4);
}

.fz-5 {
  font-size: var(--size-5);
}

.fz-6 {
  font-size: var(--size-6);
}

.fz-7 {
  font-size: var(--size-7);
}

.fz-8 {
  font-size: var(--size-8);
}

.fz-9 {
  font-size: var(--size-9);
}
