.cover {
  --cover-space: var(--size-1);

  display: flex;
  flex-direction: column;
  min-block-size: var(--cover-size, 100vh);
}

.cover > * + * {
  margin-block-start: var(--cover-space);
}

/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
.cover > *:has(~ .cover-centered) {
  margin-block-end: var(--cover-space);
}

.cover > .cover-centered {
  margin-block: auto;
}
