.m--2 {
  margin: var(--size--2);
}

.m--1 {
  margin: var(--size--1);
}

.m-0 {
  margin: var(--size-0);
}

.m-1 {
  margin: var(--size-1);
}

.m-2 {
  margin: var(--size-2);
}

.m-3 {
  margin: var(--size-3);
}

.m-4 {
  margin: var(--size-4);
}

.m-5 {
  margin: var(--size-5);
}

.m-6 {
  margin: var(--size-6);
}

.m-7 {
  margin: var(--size-7);
}

.m-8 {
  margin: var(--size-8);
}

.m-9 {
  margin: var(--size-9);
}

/**
 * Horizontal
 */

.mx--2 {
  margin-inline: var(--size--2);
}

.mx--1 {
  margin-inline: var(--size--1);
}

.mx-0 {
  margin-inline: var(--size-0);
}

.mx-1 {
  margin-inline: var(--size-1);
}

.mx-2 {
  margin-inline: var(--size-2);
}

.mx-3 {
  margin-inline: var(--size-3);
}

.mx-4 {
  margin-inline: var(--size-4);
}

.mx-5 {
  margin-inline: var(--size-5);
}

.mx-6 {
  margin-inline: var(--size-6);
}

.mx-7 {
  margin-inline: var(--size-7);
}

.mx-8 {
  margin-inline: var(--size-8);
}

.mx-9 {
  margin-inline: var(--size-9);
}

/**
 * Left
 */

.ml--2 {
  margin-inline-start: var(--size--2);
}

.ml--1 {
  margin-inline-start: var(--size--1);
}

.ml-0 {
  margin-inline-start: var(--size-0);
}

.ml-1 {
  margin-inline-start: var(--size-1);
}

.ml-2 {
  margin-inline-start: var(--size-2);
}

.ml-3 {
  margin-inline-start: var(--size-3);
}

.ml-4 {
  margin-inline-start: var(--size-4);
}

.ml-5 {
  margin-inline-start: var(--size-5);
}

.ml-6 {
  margin-inline-start: var(--size-6);
}

.ml-7 {
  margin-inline-start: var(--size-7);
}

.ml-8 {
  margin-inline-start: var(--size-8);
}

.ml-9 {
  margin-inline-start: var(--size-9);
}

/**
 * Right
 */

.mr--2 {
  margin-inline-end: var(--size--2);
}

.mr--1 {
  margin-inline-end: var(--size--1);
}

.mr-0 {
  margin-inline-end: var(--size-0);
}

.mr-1 {
  margin-inline-end: var(--size-1);
}

.mr-2 {
  margin-inline-end: var(--size-2);
}

.mr-3 {
  margin-inline-end: var(--size-3);
}

.mr-4 {
  margin-inline-end: var(--size-4);
}

.mr-5 {
  margin-inline-end: var(--size-5);
}

.mr-6 {
  margin-inline-end: var(--size-6);
}

.mr-7 {
  margin-inline-end: var(--size-7);
}

.mr-8 {
  margin-inline-end: var(--size-8);
}

.mr-9 {
  margin-inline-end: var(--size-9);
}

/**
 * Vertical
 */

.my--2 {
  margin-block: var(--size--2);
}

.my--1 {
  margin-block: var(--size--1);
}

.my-0 {
  margin-block: var(--size-0);
}

.my-1 {
  margin-block: var(--size-1);
}

.my-2 {
  margin-block: var(--size-2);
}

.my-3 {
  margin-block: var(--size-3);
}

.my-4 {
  margin-block: var(--size-4);
}

.my-5 {
  margin-block: var(--size-5);
}

.my-6 {
  margin-block: var(--size-6);
}

.my-7 {
  margin-block: var(--size-7);
}

.my-8 {
  margin-block: var(--size-8);
}

.my-9 {
  margin-block: var(--size-9);
}

/**
 * Top
 */

.mt--2 {
  margin-block-start: var(--size--2);
}

.mt--1 {
  margin-block-start: var(--size--1);
}

.mt-0 {
  margin-block-start: var(--size-0);
}

.mt-1 {
  margin-block-start: var(--size-1);
}

.mt-2 {
  margin-block-start: var(--size-2);
}

.mt-3 {
  margin-block-start: var(--size-3);
}

.mt-4 {
  margin-block-start: var(--size-4);
}

.mt-5 {
  margin-block-start: var(--size-5);
}

.mt-6 {
  margin-block-start: var(--size-6);
}

.mt-7 {
  margin-block-start: var(--size-7);
}

.mt-8 {
  margin-block-start: var(--size-8);
}

.mt-9 {
  margin-block-start: var(--size-9);
}

/**
 * Bottom
 */

.mb--2 {
  margin-block-end: var(--size--2);
}

.mb--1 {
  margin-block-end: var(--size--1);
}

.mb-0 {
  margin-block-end: var(--size-0);
}

.mb-1 {
  margin-block-end: var(--size-1);
}

.mb-2 {
  margin-block-end: var(--size-2);
}

.mb-3 {
  margin-block-end: var(--size-3);
}

.mb-4 {
  margin-block-end: var(--size-4);
}

.mb-5 {
  margin-block-end: var(--size-5);
}

.mb-6 {
  margin-block-end: var(--size-6);
}

.mb-7 {
  margin-block-end: var(--size-7);
}

.mb-8 {
  margin-block-end: var(--size-8);
}

.mb-9 {
  margin-block-end: var(--size-9);
}
